const darkTheme = {
  colors: {
    primary: '#FFFFFF',
    secondary: '#000000',
    shadow: '#FFFFFF',
    background: '#000000',
  }
};

export default darkTheme;
