const theme = {
  colors: {
    primary: '#000000',
    secondary: '#FFFFFF',
    shadow: '#000000',
    background: '#FFFFFF',


  }
};

export default theme;
