import { CodeKatasImage, VirtualCoffeeImage } from '../assets/meetups'
const events = [
  {
    title: 'Virtual Coffee',
    reoccurrence: 'every 2nd Sunday of the Month',
    img: VirtualCoffeeImage,
    description: (
      <>
        <p>Join us for an insightful discussion using the Lean Coffee format. If you don’t know what Lean Coffee is or if you’re new to the Javascript community in general, don’t worry! Lean Coffee is just an open discussion where we vote on topics to discuss. So if you have a question to ask your peers, we'll have time for a brainstorming session to collect everyone's topics. If you don't have any topics then you'll still have the ability to vote on other topics and gain or share some knowledge.</p>
        <p>If you don't know what Lean Coffee format is, it is a structured and agenda-less meeting. Conversations are directed and productive because the agenda for the meeting was democratically generated. more info <a href='http://leancoffee.org/' target='_blank' rel='noreferrer'>here</a>.</p>
      </>
    ),
  },
  {
    title: 'Code Katas',
    reoccurrence: 'every 3rd Tuesday of the Month',
    img: CodeKatasImage,
    description: (
      <>
        <p>Code Katas are a fun way to do coding exercises, in any language you want. It's a good practice to keep increasing your skills as a coder. You can practice the same kata over and over to continue improving, get feedback from others, and move on to more difficult problems to solve.</p>
        <p>This is a structured event where we will present the kata, and how to practice them, then everyone works to solve the problem on your own computer, get help from neighbors and mentors, and see how others solve the same problem!</p>
      </>
    ),
  }
];

export default events;