import React from 'react';
import { ScreenFrame, TextFrame } from '../styles';

const CodeOfConduct = () => {
  return (
    <ScreenFrame>
      <TextFrame>
        <h3>Code of Conduct</h3>
        <p>Seattle JS Hackers is a space for judgement-free learning and collaboration. We seek to support and encourage our members in a positive way; even negative feedback should be offered constructively and with respectful words.</p>
        <p>Please feel free to share your experiences with others (e.g., product, company, passion, recruitment opportunity) if asked, but save your sales pitches for other events.</p>
        <p>Remember, we are here to grow, learn, hack, and help each other!</p>
      </TextFrame>
    </ScreenFrame>
  );
};

export default CodeOfConduct;
